@import '../../assets/styles/theme-variables.scss';
@import '../../assets/styles/common-styles.scss';

.email-shipment-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 25px 25px;
    background-color: $light-bg-color;
    overflow: hidden;

    .email-header-wrapper {
        width: 100%;
        height: 100px;

        .row {
            width: 100%;
            height: 100%;
        }

        .header-flex-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .timer-text {
            width: 100%;
            font-size: 15px;
            font-weight: 700;
            padding: 10px;
            margin: 0;
            letter-spacing: 0.5px;

            &.timer-success {
                color: $success-color;
            }

            &.timer-warning {
                color: $warning-color;
            }

            &.timer-error {
                color: $error-dark-color;
            }

        }

        .button-section {
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 10px;

            .MuiButton-root {
                margin-left: 20px;
            }

        }

    }

    .email-section-wrapper {
        width: 100%;
        height: -moz-calc(100% - 100px);
        height: -webkit-calc(100% - 100px);
        height: -o-calc(100% - 100px);
        height: calc(100% - 100px);
        padding: 25px;
        position: relative;
        background-color: $white-color;
        @include box-shadow();
        @include scroll-style();

        .title-text {
            color: $primary-color;
            font-size: 20px;
            font-family: 'Roboto-Bold';
            margin-bottom: 25px;
            letter-spacing: 0.5px;
        }

        .box-content {
            width: 100%;
            padding: 20px 20px 10px;
            margin-bottom: 25px;
            border-radius: 5px;
            border: 1px solid $border-color;

            .heading-text {
                width: 100%;
                color: $primary-color;
                font-weight: bold;
                padding: 0;
                margin-bottom: 18px;
                display: flex;
                align-items: center;
                letter-spacing: 0.5px;
            }

            ul.list-content {
                width: 100%;
                float: left;
                padding: 0;

                li {
                    width: 100%;
                    float: left;
                    margin-bottom: 14px;

                    label {
                        width: 45%;
                        float: left;
                        color: $text-color;
                        font-size: 13px;
                    }

                    div.text {
                        width: 55%;
                        float: left;
                        color: $black-color;
                        padding-left: 10px;
                    }

                    .label-heading {
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        padding: 0;
                    }

                }

            }

            .core-text-sm {
                color: $black-color;
                font-weight: normal;
                font-size: 10px;
                padding: 2px 10px;
                text-transform: uppercase;
                background: $dark-green-color;
                margin-left: 5px;
                border-radius: 25px;
            }

            .noncore-text-sm {
                color: $black-color;
                font-weight: normal;
                font-size: 10px;
                padding: 2px 10px;
                text-transform: uppercase;
                background: $warning-light-color;
                margin-left: 5px;
                border-radius: 25px;
            }

            .content-text-icon {
                width: 100%;
                padding-left: 30px;
                position: relative;
                color: $text-dark-color;
                margin-bottom: 10px;

                .material-icons {
                    color: $primary-color;
                    font-size: 22px;
                    position: absolute;
                    left: 0;
                    top: -0;
                }

            }

            .content-text {
                padding: 0;
            }

            .notes-text {
                color: $black-color;
                padding: 0 0 10px;
                line-height: 25px;
            }

        }

        &.email-label {
            height: -moz-calc(100% - 170px);
            height: -webkit-calc(100% - 170px);
            height: -o-calc(100% - 170px);
            height: calc(100% - 170px);
        }

        &.link-expired-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            box-shadow: none;

            .link-expired {
                font-size: 20px;
                font-family: 'Roboto-Bold';
                color: $error-dark-color;
            }

        }

    }

    .email-label-wrapper {
        min-width: 100px;
        color: $white-color;
        font-size: 15px;
        padding: 15px 25px;
        margin-bottom: 20px;
        border-radius: 5px;
        @include box-shadow();

        &.accepted-text {
            background-color: $success-color;
        }

        &.rejected-text {
            background-color: $error-color;
        }

    }

    .grid-view {
        display: block;
    }

    .mobile-view {
        display: none;
    }

}


// Mobile View

@media only screen and (max-width: 600px) {

    .email-shipment-wrapper {
        padding: 20px;

        .email-header-wrapper {
            height: auto;
            margin-bottom: 10px;

            .timer-text {
                font-size: 14px;
            }

            .MuiButton-root {
                min-width: 90px;
            }

            .header-flex-box {
                display: block;
            }

            .timer-section {
                width: 100%;
                float: left;
                margin: 10px 0 0;

                .timer-text {
                    padding-left: 0;
                }

            }

            .button-section {
                width: 100%;
                min-width: 100%;
                float: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0;

                .MuiButton-root {
                    margin-left: 0;
                }

            }

        }

        .email-section-wrapper {
            height: -moz-calc(100% - 150px);
            height: -webkit-calc(100% - 150px);
            height: -o-calc(100% - 150px);
            height: calc(100% - 150px);
            padding: 20px;

            .title-text {
                font-size: 18px;
                margin-bottom: 15px;
            }

            .content-text {
                margin-bottom: 8px;
                padding: 0;
            }

            .box-content {
                margin-bottom: 20px;
            }

            &.email-label {
                height: -moz-calc(100% - 212px);
                height: -webkit-calc(100% - 212px);
                height: -o-calc(100% - 212px);
                height: calc(100% - 212px);
            }

            &.link-expired-wrapper {

                .link-expired {
                    font-size: 16px;
                    text-align: center;
                }

            }

        }

        .email-label-wrapper {
            padding: 10px 20px;
            margin: 10px 0 20px;
        }

        .grid-view {
            display: none;
        }

        .mobile-view {
            display: block;
        }

        .mobile-view-pr {
            padding-right: 0;
        }

        .mobile-view-mb {
            margin-bottom: 14px;
        }

    }

}


// Tablet View

@media (min-width: 768px) and (max-width: 1024px) {

    .email-shipment-wrapper {

        .email-header-wrapper {

            .MuiButton-root {
                min-width: 80px;
            }

        }

        .mobile-view-mb {
            margin-bottom: 20px;
        }

    }

}