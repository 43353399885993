$primary-color: #002a5c;
$primary-light-color: #3C528A;
$success-color: #4a9c49;
$error-color: #b00020;
$error-light-color: #D32F2F;
$error-dark-color: #FF0000;
$warning-color: #e29613;
$warning-light-color: #FFDC9F;
$warning-dark-color: #ffc400;
$title-color: #3A3A3A;
$text-color: #737684;
$text-dark-color: #636363;
$purple-color: #800080;
$dark-green-color: #4A9C4966;
$border-color: #BBBBBB;
$border-dark-color: #707070;
$excess-lead-color: #05bdc7;
$excess-lead-border-color: #0c8288;
$repost-color: #FBF9DC;
$repost-border-color: #E2D413;
$error-box-color: #ffe6e6;
$error-box-border-color: #ff4d4d;
$search-highlight-color: #ffff00;
$upload-color: #2b89ab;
$table-td-color: #EDF5EC;
$table-head-color: #eff1f6;
$placeholder-color: #B2BEB5;
$dropdown-color: #F2F2F2;
$content-color: #F3F3F3;
$light-bg-color: #F6F7F9;
$shadow-color: #0000001A;
$shadow-medium-color: #00000024;
$shadow-dark-color: #00000029;
$black-color: #000;
$white-color: #FFF;
$no-record-color: #808080;
$scroll-color: #F5F5F5;
$scroll-thumb-color: #C9C9C9;
$multi-leg-color: #dff7ff;
$table-color: #d3d3d3;
$elementHeight: 40px;
$email-shipment-color: #ffa700;
$email-shipment-border-color: #ff4d00;
$disabled-color: #f0f0f0;
$dark-background-primary: #121212;
$dark-background-variant: #333333;
$grid-row-even-bg-color: #f3f7ff;
$dark-grid-row-bg-color: #323130;
$grid-border-color: #e9e9e9;
$dark-grid-border-color: #3A3938;
$list-border-color: #dee2e6;
$assetPath: "../assets/images";
$fontPath: "../assets/fonts";