@import '../../../assets/styles/theme-variables.scss';

.reject-form-wrapper {

    .MuiDialog-paperWidthSm {
        width: 450px;
        max-width: 450px;
    }

    .MuiDialog-paper {
        margin: 0;

        .MuiDialogTitle-root {
            font-size: 16px;
            color: $white-color;
            background-color: $primary-color;

            .close-btn {
                color: $white-color;
            }

        }

        .MuiDialogContent-root {
            padding: 15px 20px 0;
            position: relative;
        }

        .MuiDialogActions-root {
            padding: 15px 20px 20px;
            display: block;
            width: 100%;
        }

        .MuiDialogActions-spacing> :not(:first-child) {
            margin-left: 15px;
        }

    }

    .content-text {
        margin-bottom: 20px;
    }

    .MuiDialogActions-root {
        text-align: right;

        .MuiButtonBase-root {
            width: 100px;
            height: 35px;
        }

    }

}