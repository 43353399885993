@import '../../../assets/styles/theme-variables.scss';

.freight-details-wrapper {
    width: 100%;
    position: relative;
    padding: 0;

    .heading {
        color: $primary-color;
        font-weight: bold;
        letter-spacing: 0.5px;
        padding: 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        .heading-status-text {
            color: $white-color;
            border-radius: 25px;
            right: 0;
            font-size: 10px;
            font-weight: normal;
            padding: 6px 15px;
            letter-spacing: 0.5px;
            background: $primary-color;
            text-transform: uppercase;

            &.dock-high-text {
                background-color: $purple-color;
            }

        }

        .add-line-item-btn {
            position: absolute;
            top: 0;
            right: 0;
        }

    }

    .paper-content {
        width: 100%;
        overflow: hidden;
        padding: 0;
        box-shadow: none;
    }

    .table-wrapper {
        padding: 0;
        max-height: 260px;
        overflow: auto;

        thead {
            th {
                color: $primary-color;
                font-weight: bold;
                font-size: 11px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                background: $table-head-color;
                border-bottom: 2px solid $white-color;
            }
        }

        tbody {

            td {
                font-size: 13px;
                border-bottom: 2px solid $white-color;
                background: $table-td-color;
            }

        }

    }

    ul.list {
        width: 100%;
        float: left;
        margin: 8px 0 10px;
        border-bottom: 1px solid $list-border-color;

        li {
            width: 100%;
            float: left;
            margin-bottom: 14px;

            label {
                width: 45%;
                float: left;
                color: $text-color;
                font-size: 13px;
            }

            div.text {
                width: 55%;
                float: left;
                color: $black-color;
                padding-left: 10px;
            }

            .label-heading {
                color: $primary-color;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0.5px;
                padding: 0;
            }

        }

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

    }

}