@import '../../assets/styles/theme-variables.scss';

.page-not-found-wrapper {
    width: 100%;
    color: $primary-color;
    background: $white-color;
    top: 60px;
    z-index: 1200;
    height: -moz-calc(100% - 60px);
    height: -webkit-calc(100% - 60px);
    height: -o-calc(100% - 60px);
    height: calc(100% - 60px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Roboto-Regular';

    h1 {
        font-size: 100px;
        margin-bottom: 25px;
    }

    h4 {
        font-size: 40px;
    }

}