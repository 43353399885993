@import './theme-variables.scss';

.bolt-express-app,
.dialog-wrapper {

    .MuiButton-root {
        min-width: 110px;
        letter-spacing: 0.5px;
        box-shadow: none;
        font-family: 'Roboto-Regular';
        white-space: nowrap;

        .MuiLoadingButton-loadingIndicatorStart {
            position: relative;
            left: -8px;
        }

    }

    .MuiButton-root:hover {
        box-shadow: none;
        border-color: $primary-color;
    }

    .MuiButton-containedPrimary,
    .MuiButton-containedPrimary:hover,
    .MuiButton-containedPrimary.Mui-disabled {
        color: $white-color;
        background: $primary-color;
    }

    .MuiButton-containedSecondary,
    .MuiButton-containedSecondary:hover,
    .MuiButton-containedSecondary.Mui-disabled {
        color: $white-color;
        background: $error-color;
    }

    .MuiButton-containedSuccess,
    .MuiButton-containedSuccess:hover,
    .MuiButton-containedSuccess.Mui-disabled {
        color: $white-color;
        background: $success-color;
    }

    .MuiButton-outlined,
    .MuiButton-outlined:hover {
        color: $primary-color;
        border-color: $primary-color;
        background: transparent;
    }

    .MuiButton-outlinedSuccess,
    .MuiButton-outlinedSuccess:hover {
        color: $success-color;
        border-color: $success-color;
    }

    .MuiCheckbox-root {
        padding: 0 9px;
    }

    .MuiCheckbox-colorPrimary.Mui-checked,
    .MuiCheckbox-colorSecondary.Mui-checked,
    .MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: $primary-color;
    }

    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
    }

    .MuiOutlinedInput-notchedOutline {
        top: 0;
    }

    .MuiOutlinedInput-input,
    .MuiSelect-outlined {
        padding: 10px 14px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        background: $white-color;
    }

    .MuiInputBase-root {
        width: 100%;
        position: relative;
        font-family: 'Roboto-Regular';
    }

    .MuiInputBase-input {
        font-size: 14px;
    }

    .MuiOutlinedInput-multiline {
        padding: 0;
    }

    .MuiFormHelperText-root {
        color: $text-color;
        font-size: 10px;
        font-family: 'Roboto-Regular';
    }

    .MuiButton-containedSizeLarge {
        padding: 6px 15px;
        height: 35px;
    }

    .MuiButton-containedSizeLarge .material-icons {
        color: $white-color;
        font-size: 20px;
        margin-right: 2px;
    }

    .MuiButton-outlined.MuiButton-outlinedSecondary {
        border-color: $border-color;
        color: $text-color;
        padding: 5px;
        min-width: auto;
        height: 35px;
    }

    .MuiButton-outlined.MuiButton-outlinedSecondary.bid-reject-btn {
        border-color: $error-color;
        color: $error-color;
        min-width: 110px;
    }

    .MuiFormLabel-root,
    .MuiMenuItem-root,
    .MuiTable-root,
    .MuiTableCell-root,
    .MuiTypography-root,
    .MuiAutocomplete-root {
        font-family: 'Roboto-Regular';
    }

    .MuiList-padding {
        padding: 0;
        font-family: 'Roboto-Regular';
    }

    .MuiButton-containedPrimary.Mui-disabled,
    .MuiButton-containedSecondary.Mui-disabled,
    .MuiButton-containedSuccess.Mui-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInputLabel-formControl {
        top: -6px;
    }

    .MuiInputLabel-shrink {
        z-index: 9999;
        background: $white-color;
        top: 0;
    }

    .MuiInputBase-multiline {
        padding: 0;
    }

    .MuiInputAdornment-root {
        margin-right: 0;
    }

    .MuiInputBase-inputAdornedStart {
        padding-left: 1px;
    }

    .MuiInputBase-input.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
        background: $disabled-color;
        cursor: not-allowed;
    }

    .MuiOutlinedInput-root.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
    }

    .MuiSwitch-switchBase.Mui-checked {
        color: $primary-color;
    }

    .MuiAutocomplete-input::-webkit-input-placeholder {
        color: $black-color;
        opacity: 1;
    }

    .MuiAutocomplete-input::-moz-placeholder {
        color: $black-color;
        opacity: 1;
    }

    .MuiAutocomplete-input:-ms-input-placeholder {
        color: $black-color;
        opacity: 1;
    }

    .MuiAutocomplete-input:-moz-placeholder {
        color: $black-color;
        opacity: 1;
    }

    .textbox-control {

        .MuiOutlinedInput-notchedOutline {
            top: -5px
        }

    }

    .textbox-control.hide-placeholder {

        .MuiOutlinedInput-notchedOutline {
            top: 0
        }

    }

    .auto-complete-control {

        .MuiOutlinedInput-root {
            padding: 0 9px;
            height: 40px;
        }

        .helper-text {
            padding-left: 15px;
        }

        &.sm {

            .MuiOutlinedInput-root {
                height: 35px;
            }

        }

        &.text-sm {

            .MuiInputBase-input {
                font-size: 13px;
            }

        }

    }

    .auto-complete-multi-control {

        .MuiOutlinedInput-root {
            padding: 0 9px;
            min-height: 40px;
        }

    }

    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background: $primary-color;
        opacity: 0.5;
    }

    .MuiRadio-root.Mui-checked,
    .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected {
        color: $primary-color;
    }

    .css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
    .css-1aquho2-MuiTabs-indicator,
    .css-13go44a-MuiButtonBase-root-MuiIconButton-root,
    .css-h7tmkn.Mui-selected,
    .css-12ha4i7,
    .css-7lip4c,
    .css-a1rc6s {
        background: $primary-color;
    }

    .css-2ujp1m {
        border-color: $primary-color;
    }

    .MuiDialog-paper {
        margin: 0;

        .MuiDialogTitle-root {
            padding: 15px 20px;
            position: relative;
            z-index: 100000;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);

            .close-btn {
                position: absolute;
                right: 15px;
                top: 12px;
            }

            .MuiTypography-h6 {
                font-family: 'Roboto-Regular';
            }
        }

        .MuiDialogContent-root {
            padding: 15px 20px 0;
            position: relative;
        }

        .MuiDialogActions-root {
            padding: 15px 20px 20px;
            display: block;
            width: 100%;
        }

        .MuiDialogActions-spacing> :not(:first-child) {
            margin-left: 15px;
        }
    }

    .MuiPaper-rounded {
        border-radius: 0;
    }

    legend.css-nnbavb {
        float: left;
    }

    .MuiIconButton-root {
        border-radius: 50%;
    }

    .MuiAccordion-root {
        width: 100%;
        box-shadow: none;

        .MuiAccordionSummary-root {
            min-height: 50px;
            background: $table-head-color;

            .MuiAccordionSummary-content {
                color: $primary-color;
                font-weight: bold;
                letter-spacing: 0.5px;
                padding: 0;
            }
        }

        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 55px;
        }

        .MuiCollapse-wrapperInner {
            background: $table-td-color;
            border-top: 2px solid $white-color;

            .MuiAccordionDetails-root {
                padding: 15px;
            }

            .no-record-text {
                font-size: 13px;
                text-align: center;
                margin-bottom: 10px;
            }

        }
    }

    .MuiStepper-root {
        font-family: 'Roboto-Regular';

        .MuiSvgIcon-root,
        .MuiStepIcon-root.Mui-active,
        .MuiStepLabel-label.Mui-active {
            color: $border-color;
        }

        .MuiStepIcon-root.Mui-completed {
            color: $success-color;
        }

        .MuiStepLabel-label {
            color: $border-color;
            font-size: 14px;
            font-family: 'Roboto-Regular';
            margin-top: 7px;
        }

        .MuiStepLabel-label.Mui-completed {
            color: $success-color;
            font-weight: bold;
            letter-spacing: 0.5px;
        }

        .MuiStepConnector-line {
            border-top-width: 2px;
        }

        .MuiStepConnector-root.Mui-active,
        .MuiStepConnector-root.Mui-completed {

            .MuiStepConnector-line {
                border-color: $success-color;
            }
        }

        .MuiSvgIcon-root {
            width: 23px;
            height: 23px;
        }

        .MuiStepIcon-text {
            opacity: 0;
        }

    }
}

.form-dialog-wrapper {

    .MuiDialog-scrollPaper {
        font-family: 'Roboto-Regular';
        justify-content: flex-end;

        .MuiDialog-paperWidthSm {
            width: 550px;
            max-width: 550px;
        }

        .MuiDialog-paperWidthMd {
            width: 750px;
            max-width: 750px;
        }

        .MuiDialog-paperWidthLg {
            width: 850px;
            max-width: 850px;
        }

        .MuiDialog-paper {
            max-width: 100%;
            height: 100%;
            max-height: 100%;
        }

        .MuiPaper-rounded {
            border-radius: 0;
        }
    }

    .MuiMenuItem-root {
        font-family: 'Roboto-Regular';
    }

    .form-dialog-profile {
        width: 150px;
        height: 150px;
        margin: 20px 0px;
        background-color: gray;
        cursor: pointer;
    }

    .form-image-uploader {
        display: flex;
        position: relative;
        justify-content: center;
        top: 40%;
        color: $white-color;
    }

}

.dialog-title-wrapper {
    padding: 10px 20px;
    color: $white-color;
    background: $primary-color;

    .MuiTypography-h6 {
        font-size: 16px;
    }

    .close-btn {
        color: $white-color;
        right: 10px;
        top: 4px;
    }

    .header-section-title {
        width: 100%;
        height: 53px;
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        .material-icons {
            font-size: 21px;
            padding-right: 5px;
        }

    }

}

.MuiDialogTitle-root.dialog-title-wrapper {
    font-size: 14px;
}

.MuiList-root {
    padding: 0 !important;

    .MuiMenuItem-root.Mui-selected,
    .MuiMenuItem-root.Mui-selected:hover {
        background-color: $table-color;
    }

    li.MuiMenuItem-root:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.select-option {
    line-height: 28px !important;
    font-family: 'Roboto-Regular' !important;
}

.MuiAutocomplete-popper {

    .MuiAutocomplete-paper {

        ul.MuiAutocomplete-listbox {
            line-height: 25px;
            font-family: 'Roboto-Regular';
            padding: 0;

            .MuiAutocomplete-option[aria-selected="true"] {
                background-color: $table-color;
            }
        }

    }
}

.MuiChip-root.Mui-disabled {
    opacity: 0.7 !important;
}

.MuiTooltip-tooltip {
    color: $white-color;
    font-size: 10px !important;
    font-family: 'Roboto-Regular' !important;
    letter-spacing: 0.5px !important;
}

.form-label .MuiCheckbox-root {
    padding: 0 9px 0 2px;
}