@import 'theme-variables';

@mixin scroll-style() {
    overflow: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    scrollbar-color: $scroll-thumb-color transparent;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: $scroll-color;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $scroll-thumb-color;
    }

}

@mixin scroll-visiblity-none-style() {
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin box-shadow($top: 0px, $right: 0px, $bottom: 10px, $color: #0000001a) {
    box-shadow: $top $right $bottom $color;
    -webkit-box-shadow: $top $right $bottom $color;
    -moz-box-shadow: $top $right $bottom $color;
    -ms-box-shadow: $top $right $bottom $color;
}

@mixin image-white-color {
    filter: brightness(0) saturate(100%) invert(99%) sepia(51%) saturate(0%) hue-rotate(198deg) brightness(106%) contrast(100%);
}

@mixin image-black-color {
    filter: brightness(0) saturate(100%);
}