@import '../../../assets/styles/theme-variables.scss';

.overlay-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .7);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.form-loader,
.form-loader-sm,
.button-loader-sm {
    border-radius: 50%;
    -webkit-animation: rotation .8s linear infinite;
    -ms-animation: rotation .8s linear infinite;
    animation: rotation .8s linear infinite;
    position: relative;
}

.form-loader {
    width: 5rem;
    height: 5rem;
    border-top: 3px solid $primary-color;
    border-right: 3px solid transparent;
}

.form-loader-sm {
    width: 2rem;
    height: 2rem;
    border-top: 3px solid $primary-color;
    border-right: 3px solid transparent;
}

.button-loader-sm {
    width: 2rem;
    height: 2rem;
    border-top: 2px solid $primary-color;
    border-right: 2px solid transparent;
}

.overlay-loader .text {
    color: $primary-color;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 600;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: 1024px) {
    .mat-menu-panel.global-content {
        left: 350px;
    }
}